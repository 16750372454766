import React from "react";
import img1 from "../../Images/shadow.png";

export default function PricingSection1() {
  return (
    <div className="HeroSection1 PricingSection1 container-fluid h-auto p-0 d-flex flex-column justify-content-between">
      <div className="main container d-flex justify-content-evenly col-md-12 col-sm-12 col-12 d-flex flex-wrap">
        <div className="d-flex justify-content-start h-auto col-md-3">
          <img className="img" src={img1} alt="Image" />
        </div>
        <div className="heading paddingSpace text-center d-flex flex-column justify-content-end px-2 col-md-6">
          <h2>Continuous Page Speed Monitoring</h2>
          <p className="pt-2 mb-5">
            Free 14-day trial · No credit card required · Get set up in minutes
          </p>
        </div>
        <div className="d-flex justify-content-end col-md-3">
          <img className="img1" src={img1} alt="Image" />
        </div>
      </div>
      {/* <div className="input-div container d-flex col-md-12 col-sm-12 col-12 d-flex flex-wrap justify-content-evenly align-items-end"></div> */}
    </div>
  );
}
