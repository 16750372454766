import React, { useState } from "react";
import image from "../../Images/Screenshot 2024-01-15 183804.png";
import image1 from "../../Images/twemoji_flag-canada.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function Summary1(data) {
  const [iconSpin, setIconSpin] = useState(false);
  const [regenerateToggle, setRegenerateToggle] = useState(true);

  const handleRegenerateClickBtn = () => {
    setIconSpin(true);
    setTimeout(() => {
      setIconSpin(false);
      if (
        data &&
        data.data &&
        data.data.response &&
        data.data.response.report_id
      ) {
        const newUrl = new URL(window.location.href);
        const reportId = data.data.response.report_id;
        newUrl.searchParams.set("report_id", reportId);
        newUrl.searchParams.set("generate", true);
        window.history.replaceState(null, null, newUrl.toString());
        window.location.reload();
      }
    }, 500);
  };
  var Bgimage = image;
  const [open, setOpen] = useState(true);
  if (
    data &&
    data.data &&
    data.data.response &&
    data.data.response.lighthouseResult &&
    data.data.response.lighthouseResult.audits &&
    data.data.response.lighthouseResult.audits["final-screenshot"] &&
    data.data.response.lighthouseResult.audits["final-screenshot"].details &&
    data.data.response.lighthouseResult.audits["final-screenshot"].details
      .data !== undefined
  ) {
    Bgimage =
      data.data.response.lighthouseResult.audits["final-screenshot"].details
        .data;
    if (open == true) {
      setOpen(false);
    }
  }
  return (
    <div className="container SummarySection">
      <div className="container p-0 summaryfirst justify-content-between pt-4 mt-4 col-md-12 col-12 d-flex flex-wrap">
        <div
          className="position-relative d-flex col-md-5 col-sm-12 image col-sm-12 col-12"
          style={{ minHeight: "385px" }}
        >
          <img src={Bgimage} alt="Image" className="w-100 h-100" />
          <Backdrop
            sx={{
              color: "#fff",
              borderRadius: "7px",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              position: "absolute",
            }}
            open={open}
          >
            <div className="container col-md-6 image col-sm-12 col-12 px-5 justify-content-center d-flex align-items-center">
              <CircularProgress color="inherit" />
            </div>
          </Backdrop>
        </div>
        <div className="position-relative ps-3 pe-2 title col-md-5 col-sm-12 col-12 overflow-auto">
          {data.data &&
            data.data.response &&
            data.data.response.lighthouseResult && (
              <div className="w-100 h-100 py-4 d-flex flex-column justify-content-center">
                <h1 className="green" style={{ whiteSpace: "nowrap" }}>
                  Latest Performance Report for :
                </h1>
                <h3
                  className="lightbrown mb-3"
                  style={{ fontSize: "25px", whiteSpace: "nowrap" }}
                >
                  <span className="spanFont">URL : </span>
                  {data.data.response.lighthouseResult.finalUrl}
                </h3>
                <p className="mb-2" style={{ fontWeight: "300" }}>
                  <sp an className="spanFont">
                    Report generated :{" "}
                  </sp>
                  {data.data.response.lighthouseResult.fetchTime &&
                    new Date(
                      data.data.response.lighthouseResult.fetchTime
                    ).toLocaleString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      timeZoneName: "short",
                    })}
                </p>
                <p className="mb-2" style={{ fontWeight: "300" }}>
                  <span className="spanFont">Test Server Location : </span>
                  <span className="spanFont">
                    <img src={image1} alt="Icon" />
                  </span>
                  Vancouver, Canada
                </p>
                <p className="mb-2" style={{ fontWeight: "300" }}>
                  <span className="spanFont">Using : </span>
                  {data.data.response.lighthouseResult.userAgent}
                </p>
              </div>
            )}
          {/* <Backdrop
            sx={{
              color: "#fff",
              borderRadius: "7px",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              position: "absolute",
              display: "flex",
              backdropFilter: "blur(10px)",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            }}
            open={open}
          >
            <div className="container col-md-6 image col-sm-12 col-12 px-5 justify-content-center d-flex align-items-center">
              <CircularProgress variant="indeterminate" color="inherit" />
            </div>
          </Backdrop> */}
        </div>

        <div className="container-fluid p-0">
          <div className="col-md-12 py-4 d-flex justify-content-end">
            <button
              className={`py-2 px-4 refresh-btn ${iconSpin ? "spin" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                handleRegenerateClickBtn();
              }}
            >
              <i
                className={`fa-solid fa-arrows-rotate me-2 ${
                  iconSpin ? "fa-spin" : ""
                }`}
              ></i>
              {regenerateToggle ? "Regenerate" : "Generate"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
