import React from 'react'
import PricingSection1 from './Pricing/PricingSection1'
import PricingSection2 from './Pricing/PricingSection2'
import PricingSection3 from './Pricing/PricingSection3'

export default function Pricing() {
  return (
    <div>
      <PricingSection1 />
      <PricingSection2 />
      <PricingSection3 />
    </div>
  )
}
