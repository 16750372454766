import React from 'react'
import ToolsSection1 from './Tools/ToolsSection1'
import ToolsSection2 from './Tools/ToolsSection2'

export default function Tools() {
  return (
    <div>
      <ToolsSection1 />
      <ToolsSection2 />
    </div>
  )
}
