import "./App.css";
import Axios from "axios";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "./Component/Home";
import Pricing from "./Component/Pricing";
import Header from "./Component/Header";
import Tools from "./Component/Tools";
import Blog from "./Component/Blog";
import Summary from "./Component/Summary";
import DomainTools from "./Component/Tools/DomainTools";
import DnsTools from "./Component/Tools/DnsTools";
import SysadminTools from "./Component/Tools/SysadminTools";
import DeveloperTools from "./Component/Tools/DeveloperTools";
import ValidationTools from "./Component/Tools/ValidationTools";
import CloudTools from "./Component/Tools/CloudTools";
import Footer from "./Component/Footer";

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

function AppContent() {
  const location = useLocation();
  const navigate = useNavigate();
  const [url, setUrl] = useState("");
  const [strategy, setStrategy] = useState("");
  const report_id = new URLSearchParams(location.search).get("report_id");
  const generate = new URLSearchParams(location.search).get("generate");
  const currentSection = new URLSearchParams(location.search).get("section");
  const [responseData, setResponseData] = useState({});

  let apiUrl = url
    ? `https://logicdigger.com/wp-json/reports/v1/analyze/?url=${encodeURIComponent(
        url
      )}&strategy=${encodeURIComponent(strategy)}`
    : "";

  if (generate && report_id) {
    apiUrl = `${apiUrl}&generate=${generate}&report_id=${report_id}`;
  }

  const fetchData = async (url) => {
    try {
      const response = await Axios.get(url);
      if (response.data) {
        setResponseData(response.data);
        //  const newUrl = new URL(window.location.href);
        //  const reportId = response.data.report_id;

        // newUrl.searchParams.set("report_id", reportId);
        // newUrl.searchParams.set("generate", false);
        // window.history.replaceState(null, null, newUrl.toString());
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(apiUrl);
  }, [location.search, apiUrl]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlParam = params.get("url");
    const strategy = params.get("strategy");
    setStrategy(strategy || "");
    setUrl(urlParam || "");
  }, [location.search]);

  const handleResetResponseData = () => {
    // Reset or unset responseData
    setResponseData({});
  };

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/home" element={<Home />} exact />
        <Route path="/pricing" element={<Pricing />} exact />
        <Route path="/tools" element={<Tools />} exact />
        <Route path="/tools/domaintools" element={<DomainTools />} exact />
        <Route path="/tools/dnstools" element={<DnsTools />} exact />
        <Route path="/tools/sysadmintools" element={<SysadminTools />} exact />
        <Route
          path="/tools/developertools"
          element={<DeveloperTools />}
          exact
        />
        <Route
          path="/tools/validationtools"
          element={<ValidationTools />}
          exact
        />
        <Route path="/tools/cloudtools" element={<CloudTools />} exact />
        <Route path="blog" element={<Blog />} />
        <Route
          path="/summary"
          element={
            <Summary
              response={responseData}
              ResetResponseData={handleResetResponseData}
            />
          }
          exact
        />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
