import React, { useState } from "react";
import img1 from "../../Images/shadow.png";
import img2 from "../../Images/undraw_online_test_re_kyfx 1.png";
import { useNavigate } from "react-router-dom";

export default function HeroSection1() {
  const [url, setUrl] = useState("");
  const [location, setlocation] = useState("San Francisco, USA");
  const navigate = useNavigate();

  const handleStartTest = () => {
    const urlPattern = /^(http:\/\/|https:\/\/|www\.)\S+$/i;

    if (urlPattern.test(url)) {
      navigate(`/summary?url=${encodeURIComponent(url)}&strategy=${location}`);
    } else if (!url.trim()) {
      alert("Please fill in the URL before starting the test.");
    } else if (!isNaN(url)) {
      alert("Please enter a valid URL, not a number.");
    } else {
      alert("Please enter a valid URL starting with http, https, or www.");
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="container-fluid p-0">
      <div className="HeroSection1 container-fluid h-auto p-0 d-flex flex-column justify-content-between">
        <div className="main container d-flex justify-content-evenly col-md-12 col-sm-12 col-12 d-flex flex-wrap">
          <div className="d-flex justify-content-start h-auto col-md-3">
            <img className="img" src={img1} alt="Image" />
          </div>
          <div className="heading paddingSpace d-flex flex-column justify-content-end px-2 col-md-6 text-center">
            <h2>Logicdigger Website Speed Test</h2>
            <p className="pt-2 mb-5">
              Enter a URL to test the page load time, analyze it, and find
              bottlenecks.
            </p>
          </div>
          <div className="d-flex justify-content-end col-md-3">
            <img className="img1" src={img1} alt="Image" />
          </div>
        </div>
        <form onSubmit={handleStartTest}>
          <div className="input-div container d-flex col-md-12 col-sm-12 col-12 d-flex flex-wrap justify-content-evenly align-items-end">
            <div className="col-md-4 col-12">
              <h5 className="text-start">URL</h5>

              <input
                className="col-md-12 col-12 ps-4 mb-4"
                type="url"
                name="url"
                placeholder="www.example.com"
                onChange={(e) => setUrl(e.target.value)}
                required
                autocomplete="url"
              />
            </div>
            <div className="col-md-4 col-12">
              <h5 className="text-start">Test From</h5>
              <div className="position-relative">
                <div className={`custom-select ${isOpen ? "open" : ""}`}>
                  <select
                    className="col-md-12 col-12 px-4 mb-4"
                    name="strategy"
                    id="strategy"
                    onClick={handleSelectToggle}
                    onChange={(e) => setlocation(e.target.value)}
                    required
                  >
                       <option value="san_francisco" selected>San Francisco, USA</option>
                    <option value="newyork">New York, USA</option>
                    <option value="london">London, UK</option>
                    <option value="tokyo">Tokyo, Japan</option>
                    <option value="sao_paulo">São Paulo, Brazil</option>
                    <option value="mumbai">Mumbai, India</option>
                    <option value="singapore">Singapore</option>
                    <option value="sydney">Sydney, Australia</option>
                    <option value="dubai">Dubai, UAE</option>
                    <option value="frankfurt">Frankfurt, Germany</option>
                 
                  </select>
                </div>
                <i
                  className={`fa-solid fa-angle-${isOpen ? "up" : "down"}`}
                  value="arrow"
                  onClick={handleSelectToggle}
                ></i>
              </div>
            </div>
            <div className="col-md-2 col-12 ">
              <button
                type="button"
                className="border-0 text-light py-2 mb-4 w-100"
                onClick={handleStartTest}
                // disabled={!Url.trim()}
              >
                Start Test
              </button>
            </div>
          </div>
        </form>
        <div className="container-fluid last-div">
          <div className="container d-flex flex-wrap justify-content-between">
            <div className="col-md-8 col-sm-12">
              <p className="p-tag mt-2 mb-1">
                The internet is fragile. Be the first to know when your site is
                in danger.
              </p>
              <div className="d-flex justify-content-end align-items-center">
                <p className="p-tag-1 m-0">Start Your Free Trial 14 Days</p>
                <i class="fa-solid fa-arrow-right-long ms-3"></i>
              </div>
            </div>
            <div className="last-img-div">
              <img className="last-img" src={img2} alt="Image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
