import React from "react";
import Image from "../../Images/Group 30.png";

export default function PricingSection2() {
  return (
    <div className="container-fluid">
      <div className="PricingSection2 paddingSpace1 container col-md-12 col-12 d-flex flex-wrap justify-content-between">
        <div className="card-item col-md-3 col-sm-12 col-12 d-flex position-relative py-3">
          <div className="container mt-5 ps-5 col-md-8 pe-0 first">
            <h2 className="pb-4">Free Trial</h2>
            <h3 className="text-light">Free</h3>
            <p className="text-white pb-4">
              For most businesses that want to otpimize web queries
            </p>
            <ul className="text-white">
              <li>All limited links</li>
              <li>Own analytics platform</li>
              <li>Chat support</li>
              <li>Optimize hashtags</li>
              <li>Unlimited users</li>
            </ul>
          </div>
          <div className="img-div col-md-4 overflow-hidden">
            <img src={Image} alt="Image" />
          </div>
          <div className="col-md-12 position-absolute text-center bottom-0 mb-5">
            <button className="Main-btn">Start Now</button>
          </div>
        </div>
        <div className="card-item col-md-3 col-sm-12 col-12 d-flex position-relative py-3">
          <div className="container mt-5 ps-5 col-md-8 pe-0">
            <div className="w-75 position-absolute top-0 left-0 d-flex justify-content-end pt-4">
                <button className="most-btn">MOST POPULAR</button>
            </div>
            <h2 className="pb-4">$39/mo</h2>
            <h3 className="text-light">Free</h3>
            <p className="text-white pb-4">
              For most businesses that want to otpimize web queries
            </p>
            <ul className="text-white">
              <li>All limited links</li>
              <li>Own analytics platform</li>
              <li>Chat support</li>
              <li>Optimize hashtags</li>
              <li>Unlimited users</li>
            </ul>
          </div>
          <div className="img-div col-md-4 overflow-hidden">
            <img src={Image} alt="Image" />
          </div>
          <div className="col-md-12 position-absolute text-center bottom-0 mb-5">
            <button className="Main-btn">Choose plan</button>
          </div>
        </div>
        <div className="card-item col-md-3 col-sm-12 col-12 d-flex position-relative py-3">
          <div className="container mt-5 ps-5 col-md-8 pe-0">
          <div className="w-75 position-absolute top-0 left-0 d-flex justify-content-end pt-4">
                <button className="most-btn">MOST POPULAR</button>
            </div>
            <h2 className="pb-4">$100/mo</h2>
            <h3 className="text-light">Free</h3>
            <p className="text-white pb-4">
              For most businesses that want to otpimize web queries
            </p>
            <ul className="text-white">
              <li>All limited links</li>
              <li>Own analytics platform</li>
              <li>Chat support</li>
              <li>Optimize hashtags</li>
              <li>Unlimited users</li>
            </ul>
          </div>
          <div className="img-div col-md-4 overflow-hidden">
            <img src={Image} alt="Image" />
          </div>
          <div className="col-md-12 position-absolute text-center bottom-0 mb-5">
            <button className="Main-btn">Choose plan</button>
          </div>
        </div>
      </div>
    </div>
  );
}
