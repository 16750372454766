import React from "react";
import { NavLink } from "react-router-dom";
import img from "../Images/Companies 1.png";

export default function Footer() {
  return (
    <div className="footer container-fluid d-flex justify-content-center align-items-center h-auto py-4 shadowBg">
      <div className="container paddingSpace1 py-4 my-4 justify-content-between h-auto col-md-12 col-sm-12 col-12 d-flex flex-wrap">
        <div className="col-md-4 col-12 text-start d-flex flex-column">
          <h2 className="lightbrown text mb-3">
            About <span className="green">Logicdigger</span>
          </h2>
          <p className="fw lightbrown">
            Logicdigger is a professional web development company offering a
            full range of web design and development services
          </p>
          <p className="fw lightbrown">
            Logicdigger is a professional web development company offering a
            full range of web design and development services
          </p>
          <img src={img} alt="Image" className="w-25"/>
        </div>
        <div className="footer-list col-sm-4 col-md-2 col-12">
          <ul className="list-group rounded-0">
            <li className="list-group-item border-0 hoverdark col-md-12 d-flex">
              <div className="col-md-4 col-12 col-12 d-none"></div>
              <div className="col-md-8 col-12 text-start">
                <a href="#">Usefull Links</a>
              </div>
            </li>
            <li className="list-group-item border-0 enable col-md-12 d-flex">
              <div className="col-md-4 col-12 d-none"></div>
              <div className="col-md-8 col-12 text-start">
                <NavLink to="/" activeClassName="green">
                  Features
                </NavLink>
              </div>
            </li>
            <li className="list-group-item border-0 hoverdark col-md-12 d-flex">
              <div className="col-md-4 col-12 d-none"></div>
              <div className="col-md-8 col-12 text-start">
                <NavLink to="/pricing" activeClassName="green">
                  Pricing
                </NavLink>
              </div>
            </li>
            <li className="list-group-item border-0 hoverdark col-md-12 d-flex">
              <div className="col-md-4 col-12 d-none"></div>
              <div className="col-md-8 col-12 text-start">
                <NavLink to="/tools" activeClassName="green">
                  Tools
                </NavLink>
              </div>
            </li>
            <li className="list-group-item border-0 hoverdark col-md-12 d-flex">
              <div className="col-md-4 col-12 d-none"></div>
              <div className="col-md-8 col-12 text-start">
                <NavLink to="/blog" activeClassName="green">
                  Blog
                </NavLink>
              </div>
            </li>
          </ul>
        </div>
        <div className="footer-list col-sm-4 col-md-2 col-12">
          <ul className="list-group rounded-0">
            <li className="list-group-item border-0 hoverdark col-md-12 d-flex">
              <div className="col-md-4 col-12 d-none"></div>
              <div className="col-md-8 col-12 text-start">
                <a href="#">Company</a>
              </div>
            </li>
            <li className="list-group-item border-0 enable col-md-12 d-flex">
              <div className="col-md-12 col-12 text-start">
                <a href="#">Why logicdigger</a>
              </div>
            </li>
            <li className="list-group-item border-0 hoverdark col-md-12 d-flex">
              <div className="col-md-4 col-12 d-none"></div>
              <div className="col-md-8 col-12 text-start">
                <a href="#">Contact</a>
              </div>
            </li>
            <li className="list-group-item border-0 hoverdark col-md-12 d-flex">
              <div className="col-md-4 col-12 d-none"></div>
              <div className="col-md-8 col-12 text-start">
                <a href="#">Jobs</a>
              </div>
            </li>
            <li className="list-group-item border-0 hoverdark col-md-12 d-flex">
              <div className="col-md-4 col-12 d-none"></div>
              <div className="col-md-8 col-12 text-start">
                <a href="#">Brand Assets</a>
              </div>
            </li>
            <li className="list-group-item border-0 hoverdark col-md-12 d-flex">
              <div className="col-md-4 col-12 d-none"></div>
              <div className="col-md-8 col-12 text-start">
                <a href="#">Customers</a>
              </div>
            </li>
          </ul>
        </div>
        <div className="footer-list col-sm-4 col-md-2 col-12">
          <ul className="list-group rounded-0">
            <li className="list-group-item border-0 hoverdark col-md-12 d-flex">
              <div className="col-md-4 col-12 d-none"></div>
              <div className="col-md-8 col-12 text-start">
                <a href="#">Follow Us</a>
              </div>
            </li>
            <li className="list-group-item icon m-left border-0 d-flex justify-content-start align-items-center col-md-12">
              <div>
                <i class="fa-brands fa-twitter d-block pe-2 lightBlue col-md-6 col-6"></i>
              </div>
              <div className="col-md-6 col-6 w-auto text-center">
                <a href="#">Twitter</a>
              </div>
            </li>
            <li className="list-group-item icon m-left border-0 d-flex justify-content-start col-md-12">
              <div>
                <i class="fa-brands fa-facebook d-block pe-2 lightBlue col-md-6 col-6"></i>
              </div>
              <div className="col-md-6 col-6 w-auto text-center">
                <a href="#">Facebook</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
