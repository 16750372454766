import { Link } from "@mui/material";
import React from "react";

export default function Timeline({ response }) {
  let images = [];

  if (
    response &&
    response.response &&
    response.response.lighthouseResult &&
    response.response.lighthouseResult.audits &&
    response.response.lighthouseResult.audits["screenshot-thumbnails"] &&
    response.response.lighthouseResult.audits["screenshot-thumbnails"]
      .details &&
    response.response.lighthouseResult.audits["screenshot-thumbnails"].details
      .items !== undefined
  ) {
    images =
      response.response.lighthouseResult.audits["screenshot-thumbnails"].details
        .items;
  }
  console.log('digger1');
  console.log(response);
  console.log('digger2');
  return (
    <>
    {(response && response.response) !== undefined && (
 
    <>
      <div className="container col-md-12 flex-wrap d-flex Structure d-flex justify-content-between align-items-center">
        <div className="col-md-6 col-12">
          <h4 className="lightblack m-0">Timeline Screenshots</h4>
        </div>
        <div
          className="col-md-6 tab py-4 col-12 d-flex justify-content-end flex-wrap"
          style={{ gap: "13px" }}
        >
          <a href="/" className="text-decoration-none">
            <button className="btn">
              <i class="fa-solid fa-check pe-2"></i>New Search
            </button>
          </a>
          <button className="btn">
            <i class="fa-solid fa-file-pdf pe-2"></i>Download PDF
          </button>
        </div>
      </div>
      <div className="container imgContainer">
        <div className="container p-0 Timeline justify-content-between col-md-12 d-flex">
          <div className="container p-0 d-flex" style={{ gap: "21.70px" }}>
            {images.map((image, index) => (
              <div key={index} className="fram my-2 p-0">
                <span className="d-block">
                  {image?.timing > 1024
                    ? `${(image?.timing / 1000).toFixed(2)} Sec`
                    : `${image?.timing} Ms`}
                </span>
                <img
                  src={image?.data}
                  alt={`Image ${index}`}
                  className="h-100"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
 )}
  </>
  );
}
