import React from "react";
import img from "../../Images/Group 33.png";

export default function HeroSection3() {
  return (
    <div className="HeroSection2 container-fluid d-flex py-5">
      <div className="container paddingSpace1 d-flex col-md-12 flex-wrap flex-row-reverse">
        <div className="img-div d-flex justify-content-center align-items-center col-md-6 col-12">
          <img className="w-75 " src={img} alt="Image" />
        </div>
        <div className="content-div d-flex flex-column justify-content-center col-md-6 col-12 ps-5">
          <h2 className="text-start font mb-4">Monitor Site Speed Over Time</h2>
          <div className="d-flex align-items-center mb-2">
            <span className="d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-angle-right green pe-2"></i>
            </span>
            <h4 className="text-start green m-0">
              Track your metrics with a paid Logicdigger account
            </h4>
          </div>
          <p className="text-start ps-3">
            See how your metrics change over time and compare
            <br /> historical test results to each other to see what
            <br /> changed.
          </p>
          <div className="d-flex align-items-center mb-2">
            <span className="d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-angle-right green pe-2"></i>
            </span>
            <h4 className="text-start green m-0">Advanced lab-based tests</h4>
          </div>
          <p className="text-start ps-3">
            Test site speed from 20 global test locations using
            <br /> mobile and desktop devices. Set up performance
            <br /> budgets and test pages that require authentication.
          </p>
          <div className="d-flex align-items-center mb-2">
            <span className="d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-angle-right green pe-2"></i>
            </span>
            <h4 className="text-start green m-0">
              Recommendations to speed up your website
            </h4>
          </div>
          <p className="text-start ps-3">
            Logicdigger real-user monitoring shows you how your
            <br /> visitors experience your website and points out what
            <br /> to improve for the biggest impact.
          </p>
        </div>
      </div>
    </div>
  );
}
