import React, { useState } from "react";
import img1 from "../../Images/shadow.png";
import { Link } from "react-router-dom";

export default function ToolsSection1() {
  const [activeStates, setActiveStates] = useState({});

  const handleButtonClick = (buttonId) => {
    setActiveStates((prevActiveStates) => ({
      ...prevActiveStates,
      [buttonId]: true,
    }));
  };

  const buttonsData = [
    { id: "allTools", label: "All Tools", to: "/tools" },
    { id: "domainTools", label: "Domain Tools", to: "/tools/domaintools" },
    { id: "dnsTools", label: "DNS Tools", to: "/tools/dnstools" },
    {
      id: "sysadminTools",
      label: "Sysadmin Tools",
      to: "/tools/sysadmintools",
    },
    {
      id: "developerTools",
      label: "Developer Tools",
      to: "/tools/developertools",
    },
    {
      id: "validationTools",
      label: "Validation Tools",
      to: "/tools/validationtools",
    },
    { id: "cloudTools", label: "Clouds Tools", to: "/tools/cloudtools" },
  ];

  return (
    <>
      <div className="HeroSection1 ToolsSection1 container-fluid h-auto p-0 d-flex flex-column justify-content-between">
        <div className="main container d-flex justify-content-evenly col-md-12 col-sm-12 col-12 d-flex flex-wrap">
          <div className="d-flex justify-content-start h-auto col-md-3">
            <img className="img" src={img1} alt="Image" />
          </div>
          <div className="heading paddingSpace d-flex flex-column justify-content-end px-2 col-md-6 text-center">
            <h2 className="text-center">Tools</h2>
            <p className="pt-2 mb-5">
              Free 14-day trial · No credit card required · Get set up in
              minutes
            </p>
          </div>
          <div className="d-flex justify-content-end col-md-3">
            <img className="img1" src={img1} alt="Image" />
          </div>
        </div>
        <div className="input-div container d-flex col-md-12 col-sm-12 col-12 d-flex flex-wrap justify-content-center align-items-center">
          <div className="position-relative p-0 container">
            <i className="fa-solid fa-magnifying-glass position-absolute left-0 top-50 transform-translate-n50 start-2 translate-middle-y ps-3 fs-4"></i>
            <input
              type="text"
              className="form-control py-2 ps-5"
              placeholder="Type a Tool Name And Description"
              style={{ textOverflow: "ellipsis" }}
            />
          </div>
          <div className="container searchBoxHeading d-flex mt-4 ps-5">
            <div>
              <p className="m-0" style={{ color: "#5E5E5E" }}>
                <b>Popular tools:</b>
              </p>
            </div>
            <div>
              <span
                className="container d-block"
                style={{
                  color: "#5E5E5E",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                Check Website Availability, Ping your Website or Webserver, Find
                IP Address, Find Location of your Domain ,IPv4 Converter
              </span>
              <hr
                style={{
                  height: "1px",
                  width: "100%",
                  margin: "0px",
                  color: "#5E5E5E",
                  height: "1.5px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="btn-div container-fluid my-5 ">
        <div className="container paddingSpace1 col-md-12 d-flex flex-wrap">
          <div
            className="col-md-12 col-12 d-flex flex-wrap justify-content-evenly px-4 mb-5 margin"
            style={{ gap: "20px" }}
          >
            {buttonsData.map((button) => (
              <Link to={button.to} key={button.id}>
                <button
                  className={"col-md-2 col-12"}
                  onClick={() => handleButtonClick(button.id)}
                >
                  {button.label}
                </button>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
