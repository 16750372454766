import React from 'react'
import Summary1 from './Summary1'

export default function Video(response) {
  return (
    <>
      <div className="container-fluid">
        <div className="container">Video</div>
      </div>
    </>
  )
}
