import React from "react";
import img from "../../Images/Group 34.png";

export default function HeroSection4() {
  return (
    <div className="HeroSection2 container-fluid d-flex py-5">
      <div className="container paddingSpace1 d-flex  flex-wrap col-md-12">
        <div className="img-div d-flex justify-content-center align-items-center col-md-6 h-auto col-12">
          <img className="w-75 " src={img} alt="Image" />
        </div>
        <div className="content-div d-flex flex-column justify-content-center col-md-6 col-12 h-auto ps-5">
          <h2 className="text-start font mb-4">Learn About Web Performance</h2>
          <div className="d-flex align-items-center mb-2">
            <span className="d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-angle-right green pe-2"></i>
            </span>
            <h4 className="text-start green m-0">
              Learn how to read a request waterfall
            </h4>
          </div>
          <p className="text-start ps-3">
            Find out how to use waterfall charts to understand and
            <br /> optimize the speed of your website.
          </p>
          <div className="d-flex align-items-center mb-2">
            <span className="d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-angle-right green pe-2"></i>
            </span>
            <h4 className="text-start green m-0">
              Learn how to optimize Largest Contentful Paint
            </h4>
          </div>
          <p className="text-start ps-3">
            Analyze page speed to see what resources your
            <br /> website loads, watch how it loads step by step, and
            <br /> check if third-party code is slowing you down.
          </p>
          <div className="d-flex align-items-center mb-2">
            <span className="d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-angle-right green pe-2"></i>
            </span>
            <h4 className="text-start green m-0">
              Learn how to optimize Largest Contentful Paint
            </h4>
          </div>
          <p className="text-start ps-3">
            Analyze page speed to see what resources your
            <br /> website loads, watch how it loads step by step, and
            <br /> check if third-party code is slowing you down.
          </p>
        </div>
      </div>
    </div>
  );
}
