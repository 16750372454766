import React from "react";

export default function Box({ response }) {
  const metrics = [
    "first-contentful-paint",
    "largest-contentful-paint",
    "interactive",
    "total-blocking-time",
    "speed-index",
    "cumulative-layout-shift",
    "max-potential-fid",
    "network-server-latency",
  ];

  const determineColor = (score) => {
    let bgColor, textColor, backColor, categoryText;
    if (score < 30) {
      bgColor = "#FFF2DD"; // Red
      backColor = "#e45858";
      textColor = "#e45858";
      categoryText = "Need Some Improvement";
    } else if (score >= 30 && score < 70) {
      bgColor = "#FFF2DD"; // Orange
      backColor = "#F6AB34";
      textColor = "#F6AB34";
      categoryText = "Average";
    } else if (score >= 70 && score <= 100) {
      bgColor = "#E6EEDB"; // Green
      backColor = "#36A927";
      textColor = "#36A927";
      categoryText = "Good-Nothing to do here";
    } else {
      bgColor = "#E6E6E6"; // Default
      backColor = "#939393";
      textColor = "#FFFFFF";
      categoryText = "Data Not Found!"; // Set your default category text here
    }

    return { bgColor, textColor, backColor, categoryText };
  };

  return (
    <>
    <div className="container">

      <div
        className="container p-0 col-md-12 d-flex flex-wrap justify-content-start boxSection my-4"
        style={{ gap: "28px" }}
        >
        {metrics.map((metric, index) => {
          const { bgColor, textColor, backColor, categoryText } =
          determineColor(
            response?.response?.response?.lighthouseResult?.audits[metric]
            ?.score * 100
            );
          return (
            <div
            key={index}
            className={`container box d-flex flex-column justify-content-between p-0 m-0`}
            style={{ backgroundColor: bgColor, color: textColor }}
            >
              <div
                className="text-white m-0 d-flex align-items-center justify-content-center"
                style={{ backgroundColor: backColor }}
                >
                {
                  response?.response?.response?.lighthouseResult?.audits[metric]
                  ?.title
                }
              </div>
              <h3 className="text-center m-0">
                {
                  response?.response?.response?.lighthouseResult?.audits[metric]
                  ?.displayValue
                }
              </h3>
              <div
                className="text-white m-0 d-flex align-items-center justify-content-center"
                style={{ backgroundColor: backColor }}
                >
                {categoryText}
              </div>
            </div>
          );
        })}
      </div>
          </div>
      <hr className="mb-0 mt-5 green" style={{ height: "5px" }} />
    </>
  );
}

// import React from "react";

// export default function Box(response) {
//   const metrics = [
//     "first-contentful-paint",
//     "largest-contentful-paint",
//     "interactive",
//     "total-blocking-time",
//     "speed-index",
//     "cumulative-layout-shift",
//   ];

//   const determineColor = (score) => {
//     if (score < 30) return "#FF0000"; // Red
//     if (score >= 30 && score < 70) return "#FFA500"; // Yellow
//     if (score >= 70 && score <= 100) return "#4CAF50"; // Green
//     return "#4CAF50"; // Default color
//   };
//   console.log(response);
//   return (
//     <>
//       <div className="container p-0 col-md-12 d-flex flex-wrap justify-content-between boxSection">
//         {metrics.map((metric, index) => (
//           <div
//             key={index}
//             className="container d-flex my-3 mx-1 col-md-3 col-12 flex-wrap justify-content-center p-0"
//           >
//             <div
//               className={`container box d-flex flex-column justify-content-between p-0 m-0`}
//             >
//               <div className="text-white m-0 d-flex align-items-center justify-content-center">
//                 {
//                   response.response.response.response.lighthouseResult?.audits[
//                     metric
//                   ]?.title
//                 }
//               </div>
//               <h3 className="text-center green m-0">
//                 {
//                   response.response.response.response.lighthouseResult?.audits[
//                     metric
//                   ]?.displayValue
//                 }
//               </h3>
//               <div className="text-white m-0 d-flex align-items-center justify-content-center">
//                 Good-Nothing to do here
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//       <hr className="mb-0 mt-5 green" style={{ height: "5px" }} />
//     </>
//   );
// }
