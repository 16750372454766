import React, { useState } from "react";

export default function Structure({ response }) {
  const orderedDiagnostics = [
    { key: "render-blocking-resources", label: ["FCP", "LCP"] },
    { key: "server-response-time", label: ["FCP", "LCP"] },
    { key: "uses-responsive-images", label: "" },
    { key: "unminified-css", label: ["FCP", "LCP"] },
    { key: "unused-css-rules", label: ["FCP", "LCP"] },
    { key: "unused-javascript", label: "" },
    { key: "unminified-javascript", label: ["FCP", "LCP"] },
    { key: "modern-image-formats", label: "" },
    { key: "uses-rel-preload", label: ["FCP", "LCP"] },
    { key: "prioritize-lcp-image", label: "LCP" },
    { key: "redirects", label: ["FCP", "LCP"] },
    { key: "legacy-javascript", label: "TBT" },
    { key: "offscreen-images", label: "" },
    { key: "unsized-images", label: "CLS" },
    { key: "uses-long-cache-ttl", label: "CLS" },
    { key: "layout-shift-elements", label: "CLS" },
    { key: "user-timings", label: "" },
    { key: "total-byte-weight", label: "" },
    { key: "dom-size", label: "TBT" },
    { key: "critical-request-chains", label: "" },
    { key: "bootup-time", label: "TBT" },
    { key: "mainthread-work-breakdown", label: "TBT" },
    { key: "third-party-summary", label: "TBT" },
    { key: "long-tasks", label: "TBT" },
    { key: "non-composited-animations", label: "CLS" },
    { key: "uses-optimized-images", label: "" },
    { key: "uses-text-compression", label: ["FCP", "LCP"] },
    { key: "uses-rel-preconnect", label: ["FCP", "LCP"] },
    { key: "efficient-animated-content", label: "LCP" },
    { key: "duplicated-javascript", label: "TBT" },
  ];

// Assuming response.response.lighthouseResult exists and is an object
const diagnostics = orderedDiagnostics.sort((a, b) => {
  const numericValueA = response.response.lighthouseResult?.audits[a.key]?.numericValue || 0;
  const numericValueB = response.response.lighthouseResult?.audits[b.key]?.numericValue || 0;

  // Sorting in descending order (higher numericValue first)
  return numericValueB - numericValueA;
});

console.log(orderedDiagnostics);

  const filters = [
    "All",
    `${response.response.lighthouseResult?.categories.performance.auditRefs[0].acronym}`,
    `${response.response.lighthouseResult?.categories.performance.auditRefs[1].acronym}`,
    `${response.response.lighthouseResult?.categories.performance.auditRefs[2].acronym}`,
    `${response.response.lighthouseResult?.categories.performance.auditRefs[3].acronym}`,
  ];
  console.log();

  const [openIndex, setOpenIndex] = useState(null);
  const [activeFilter, setActiveFilter] = useState(filters[0]);

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
    setOpenIndex(null);
  };

  const getFilterClass = (filter) => {
    return `${activeFilter === filter ? " active" : ""}`;
  };

  const getResourceData = (key) => {
    const audit = response.response.lighthouseResult?.audits[key];
    return {
      title: audit?.title,
      numericValue: audit?.numericValue,
      description: audit?.description,
      headings: audit?.details?.headings,
      items: audit?.details?.items,
    };
  };

  const renderHeadings = (headings) =>
    headings
      ? headings.map((heading, index) => (
          <div
            key={index}
            className={`col-md-${
              index === 0
                ? heading.valueType === "url"
                  ? "4"
                  : "2"
                : heading.valueType === "url"
                ? "4"
                : Math.floor(12 / headings.length)
            } ${heading.valueType === "url" ? "ps-4" : "text-center"}`}
            style={{ height: "auto" }}
          >
            <p className="m-0">{heading.label}</p>
          </div>
        ))
      : "";

  const renderItemData = (item, headings) =>
    item
      ? headings.map((heading, index) => (
       
          <div
            key={index}
            className={`col-md-${
              index === 0
                ? heading.valueType === "url"
                  ? "4"
                  : "2"
                : heading.valueType === "url"
                ? "4"
                : Math.floor(12 / headings.length)
            } ${heading.valueType === "url" ? "" : "text-center"}`}
            style={{ height: "auto" }}
          >
            <span
              className="m-0"
              style={{
                whiteSpace: "nowrap",
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {heading.valueType === "url" && item[heading.key] ? (
                <a
                  href={item[heading.key]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item[heading.key]}
                </a>
              ) : typeof item[heading.key] === "object" ? (
                item[heading.key].snippet ? (
                  <img src={item["url"]} alt="Snippet"></img>
                ) : (
                  ""
                )
              ) : (
                item[heading.key]
              )}
            </span>
          </div>
        ))
      : "";

  const renderClickableDescription = (description) => {
    const parts = description.split(
      /\[([^\]]+)\]\s*\(\s*(https?:\/\/[^\s]+)\s*\)\./
    );

    return (
      <div>
        <p className="mt-3">
          {parts.map((part, index) => {
            if (index % 3 === 0) {
              return <span key={index}>{part}</span>;
            } else if (index % 3 === 1) {
              return (
                <a
                  key={index}
                  href={parts[index + 1]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {part}
                </a>
              );
            }
            return null;
          })}
        </p>
      </div>
    );
  };

  // #8CC63E green Normal
  // #F6AB34 orange Medium
  // #E45858 red High

  const determineColor = (numericValue) => {
    if (isNaN(numericValue)) {
      numericValue = 0;
    }

    let bgColor, textColor;
    if (numericValue < 30) {
      bgColor = "#8CC63E"; // Green
      textColor = "#fff";
    } else if (numericValue >= 30 && numericValue < 70) {
      bgColor = "#F6AB34"; // Orange
      textColor = "#fff";
    } else {
      bgColor = "#E45858"; // Default
      textColor = "#fff";
    }

    return { bgColor, textColor };
  };
  

  return (
    <>
      <div className="container col-md-12 Structure Summary2 d-flex align-items-center flex-wrap my-5">
        <div className="container p-0 col-md-5 col-12 d-flex align-items-center">
          <h4 className="m-0">
            Boost performance
            <span className="tooltip-container-2">
              <i
                className="fa-solid fa-circle-question green mx-2"
                style={{ cursor: "pointer" }}
              ></i>
              <span className="tooltip-text-2">
                Enhance system speed, efficiency, and user interactions by
                optimizing code and minimizing load times. Boosting performance
                results in faster, more seamless experiences, improving user
                satisfaction and engagement.
              </span>
            </span>
          </h4>
        </div>
        <div
          className="container p-0 col-md-7 col-12 d-flex justify-content-end flex-wrap py-4 tab"
          style={{ gap: "12px" }}
        >
          {filters.map((filter, index) => (
            <button
              key={index}
              className={getFilterClass(filter)}
              onClick={() => handleFilterClick(filter)}
            >
              {filter}
            </button>
          ))}
        </div>
      </div>

      <div className="container Structure">
        <div className="container padding">
          <div className="d-flex justify-content-between">
            <h4 className="text-center mb-5">Opportunity</h4>
            <h4 className="text-center mb-5">Potential Savings</h4>
          </div>
          <div className="borderLineIn">
            <div className="about-list container px-0">
              {diagnostics.map((diagnostic, index) => {
                const isSelected =
                  activeFilter === "All" ||
                  (Array.isArray(diagnostic.label)
                    ? diagnostic.label.includes(activeFilter)
                    : diagnostic.label === activeFilter ||
                      diagnostic.label[0] === activeFilter);

                return (
                  isSelected && (
                    <div key={index} className="borderLine">
                      <div
                        className="col-12 col-md-12 Line d-flex justify-content-between align-items-center"
                        onClick={() => toggleAccordion(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <h4 className="col-7 col-md-9 col-sm-8 m-0">
                          {getResourceData(diagnostic.key).title}
                        </h4>
                        <div className="col-5 col-md-3 col-sm-4 d-flex align-items-center justify-content-end">
                          <span
                            style={{
                              backgroundColor: determineColor(
                                getResourceData(diagnostic.key).numericValue
                              ).bgColor,
                              color: determineColor(
                                getResourceData(diagnostic.key).numericValue
                              ).textColor,
                              padding: "2px 5px",
                              borderRadius: "5px",
                              display: "inline-block",
                            }}
                          >
                            <span
                              style={{
                                color: determineColor(
                                  getResourceData(diagnostic.key).numericValue
                                ).textColor,
                              }}
                            >
                              {getResourceData(diagnostic.key).numericValue
                                ? parseFloat(
                                    getResourceData(diagnostic.key)
                                      .numericValue / 1000
                                  ).toFixed(2)
                                : "0.00"}{" "}
                              Sec
                            </span>
                          </span>

                          <i
                            className={`fa-solid fa-chevron-${
                              openIndex === index ? "up" : "down"
                            }`}
                          ></i>
                        </div>
                      </div>
                      <div
                        className={`drop-down ${
                          openIndex === index ? "open" : "closed"
                        }`}
                        style={{
                          maxHeight: openIndex === index ? "500%" : "0",
                          opacity: openIndex === index ? "1" : "0",
                          overflow: "auto",
                          transition: "max-height 0.3s ease, opacity 0.3s ease",
                        }}
                      >
                        {getResourceData(diagnostic.key).description &&
                          renderClickableDescription(
                            getResourceData(diagnostic.key).description
                          )}
                        <div className="container px-3 Structure-Table">
                          <div className="container heading border-bottom-2 p-0 col-md-12 d-flex py-3 mt-5">
                            {renderHeadings(
                              getResourceData(diagnostic.key).headings
                            )}
                          </div>
                          { getResourceData(diagnostic.key).items
                            ? getResourceData(diagnostic.key).items.map(
                                (item, itemIndex) => (
                                  <div
                                    key={itemIndex}
                                    className="container overflow-auto table mb-0 border-bottom-2 p-0 align-items-center col-md-12 d-flex py-3"
                                  >
                                    {renderItemData(
                                      item,
                                      getResourceData(diagnostic.key).headings
                                    )}
                                  </div>
                                )
                              )
                            : ""}
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
