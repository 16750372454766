import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import logo from "../Images/Companies 1.png";

export default function Header() {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(false);

  const handleNavbarToggle = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  const handleOutsideClick = (event) => {
    if (
      isNavbarCollapsed &&
      navbarRef.current &&
      !navbarRef.current.contains(event.target) &&
      event.target.getAttribute("data-bs-toggle") !== "collapse"
    ) {
      setIsNavbarCollapsed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [isNavbarCollapsed]);

  const navbarRef = useRef(null);

  const menuItems = [
    { to: "/", label: "Features" },
    { to: "/pricing", label: "Pricing" },
    { to: "/tools", label: "Tools" },
    { to: "/blog", label: "Blog" },
  ];
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white p-0 shadowBg1">
        <div className="container" ref={navbarRef}>
          <NavLink className="navbar-brand" to="/">
            <img src={logo} alt="Image logo" />
          </NavLink>
          <button
            className={`navbar-toggler custom-toggler ${
              isNavbarCollapsed ? "collapsed" : ""
            }`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={isNavbarCollapsed ? "false" : "true"}
            aria-label="Toggle navigation"
            onClick={handleNavbarToggle}
          >
            <span className="navbar-toggler-icon-1">
              {isNavbarCollapsed ? (
                <i className="fa-solid fa-x fs-3"></i>
              ) : (
                <i className="fas fa-bars fs-3"></i>
              )}
            </span>
          </button>

          <div
            className={`collapse navbar-collapse justify-content-end py-4 text-center ${
              isNavbarCollapsed ? "show" : ""
            }`}
            id="navbarNav"
          >
            <ul className="navbar-nav">
              {menuItems.map((item, index) => (
                <li className="nav-item" key={index}>
                  <NavLink
                    className="nav-link hoverdark"
                    to={item.to}
                    activeClassName="green"
                  >
                    {item.label}
                  </NavLink>
                </li>
              ))}
            </ul>
            <button className="border-0 text-light px-5 py-2">Log in</button>
          </div>
        </div>
      </nav>
    </>
  );
}
