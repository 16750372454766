import React from "react";
import img1 from "../../Images/undraw_page_not_found_re_e9o6 1.png";
import img2 from "../../Images/icon.svg";
import img3 from "../../Images/pic this.png";

export default function BlogSection3() {
  return (
    <div className="BlogSection2 BlogSection3 container-fluid">
      <div className="col-md-12 paddingSpaceBottom container text-center pb-5">
        <h2>
          "Navigating the Maze: Uncovering Common Website
          <br /> Errors"
        </h2>
      </div>
      <div className="container paddingSpaceBottom d-flex justify-content-evenly mt-5">
        <div className="d-flex flex-column justify-content-center col-md-4">
          <i class="fa-brands fa-linkedin fs-4 py-1"></i>
          <i class="fa-brands fa-twitter fs-4 py-1"></i>
          <i class="fa-brands fa-facebook fs-4 py-1"></i>
        </div>
        <div className="text-center d-flex justify-content-center">
          <img src={img1} alt="Image" className="w-100 h-100"/>
        </div>
        <div className="d-flex flex-column col-md-4"></div>
      </div>
      <div className="container paragraph d-flex align-items-center">
        <p className="lightbrown">
          In the vast realm of the internet, website errors are the unwelcome
          gremlins that can disrupt the otherwise smooth user experience. They
          come in various forms, from the notorious "404 Page Not Found" to the
          cryptic "500 Internal Server Error." These glitches can frustrate
          visitors and potentially drive them away from your site. Broken links,
          slow loading times, and unresponsive pages are like roadblocks on the
          information highway, hindering users' access to your content. Security
          vulnerabilities, such as Cross-Site Scripting (XSS) and SQL injection,
          pose serious threats, compromising user data and the overall integrity
          of your site. However, understanding and addressing these errors is
          crucial for maintaining a functional, trustworthy, and user-friendly
          website. In upcoming posts, we'll explore these issues in detail and
          provide practical tips to keep your website running smoothly and
          error-free. After all, in the digital age, a seamless online presence
          is essential for success.
        </p>
      </div>
      <div className="icon-div container d-flex justify-content-between fs-4 align-items-center">
        <div className="d-flex">
          <h6 className="pe-2 light m-0">6 June 2023</h6>
          <h6 className="green m-0">
            Logicdigger
            <span>
              <img src={img2} alt="Icon" />
            </span>
          </h6>
        </div>
        <div className="d-flex align-items-center">
        <h6 className="m-0 light">Admin</h6>
          <img src={img3} alt="Icon" className="fs-1"/>
        </div>
      </div>
    </div>
  );
}
