import React from "react";
import ToolsSection1 from "./ToolsSection1";

export default function SysadminTools() {
  const toolCategories = [
    {
      name: "Sysadmin Tools",
      tools: [
        "Check Website Availability",
        "Ping your Website or Webserver",
        "Find IP Address",
        "Monitor SSL Certificate",
        "Brand Reputation Check",
        "SPF Record Checker",
        "Check Website Availability",
      ],
    },
  ];
  return (
    <>
      <ToolsSection1 />
      <div className="ToolsSection2 container-fluid">
        <div
          className="container paddingSpace1 col-md-12 d-flex flex-wrap justify-content-center my-5"
          style={{ gap: "50px" }}
        >
          {toolCategories.map((category, index) => (
            <div key={index} className="box col-md-3 col-12">
              <div className="container d-flex justify-content-between align-items-center my-4 px-4">
                <div>
                  <h3 className="m-0">{category.name}</h3>
                  <hr
                    className="m-0"
                    style={{ height: "3px", width: "100%", color: "#8CC63E" }}
                  />
                </div>
                <div>
                  <i className="fa-solid fa-magnifying-glass fs-5"></i>
                </div>
              </div>
              {category.tools.map((tool, toolIndex) => (
                <div
                  key={toolIndex}
                  className="d-flex align-items-center my-3 px-3"
                >
                  <div className="container px-0 box-demo text-center col-md-12 d-flex justify-content-between align-items-center w-100">
                    <button
                      type="text"
                      className="box-demo ps-3 col-md-10 w-75"
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {tool}
                    </button>
                    <i
                      className="fa-solid fa-arrow-right fs-5 col-md-2 pe-3"
                      style={{ right: "50px", top: "13px" }}
                    ></i>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
