import React from "react";
import Box from "./Box";

export default function Performance(response) {
  const dataArray =
    response.response.response.lighthouseResult?.audits["network-requests"]
      .details.items;
  const totalRequests = dataArray?.length;
  const totalResourceSize = dataArray?.reduce(
    (total, data) => total + parseInt(data.resourceSize, 10),
    0
  );
  return (
    <>
      <hr className="mb-0 mt-5 green" style={{ height: "5px" }} />
      <div className="container-fluid Performance p-0">
      <div className="container d-flex col-md-12 flex-wrap">
          <div className="col-md-12 col-12">
            <h4 className="mt-5 lightblack">
              Core Web Vitals
              <span className="tooltip-container-2">
                <i
                  className="fa-solid fa-circle-question green mx-2"
                  style={{ cursor: "pointer" }}
                ></i>
                <span className="tooltip-text-2">
                  Core Web Vitals are essential performance metrics that assess
                  webpage loading speed, interactivity, and visual stability.
                  LCP, FID, and CLS are key indicators, guiding optimizations
                  for a better user experience and improved overall web
                  performance.
                </span>
              </span>
            </h4>
            <h3
              className="lightbrown mb-3 col-md-12 text-break"
              style={{ fontSize: "16px", color: "#7D8B97" }}
            >
              <span>URL : </span>
              {response.response.response.lighthouseResult?.finalUrl}
            </h3>
          </div>
        </div>
      <Box response={response} />
      </div>
      <div className="container p-0 col-md-12 flex-wrap pb-4 d-flex Structure d-flex justify-content-between align-items-center">
        <div className="container col-md-12 col-12 mt-5">
          <h4 className="lightblack">General Information</h4>
        </div>
      </div>
      <div className="container-fluid Performance p-0">
        <div className="container meter-box col-md-12 d-flex flex-column flex-wrap my-4">
          <div
            className="container d-flex flex-wrap space1 p-0"
            style={{ gap: "28px" }}
          >
            <div className="box1">
              <h4 className="text-center text-color">Pagespeed Score</h4>
              <h4 className="lightblack">
                {(
                  response?.response?.response?.lighthouseResult?.audits[
                    "speed-index"
                  ].score * 100
                ).toFixed(2)}
              </h4>
            </div>
            <div className="box1">
              <h4 className="text-color">Load Time</h4>
              <h4 className="lightblack">
                {
                  response?.response?.response?.lighthouseResult?.audits[
                    "speed-index"
                  ].displayValue
                }
              </h4>
            </div>
            <div className="box1">
              <h4 className="text-center text-color">Size</h4>
              <h4 className="lightblack m-0">
                {totalResourceSize
                  ? `${(totalResourceSize / 1024 / 1024).toFixed(2)} MB`
                  : "0.00 MB"}
              </h4>
            </div>
            <div className="box1">
              <h4 className="text-center text-color">Requests</h4>
              <h4 className="lightblack">{totalRequests}</h4>
            </div>
            <div className="box1">
              <h4 className="text-center text-color">Location</h4>
              <h4 className="lightblack">New York-1</h4>
            </div>
            <div className="box1">
              <h4 className="text-center text-color">Device</h4>
              <h4 className="lightblack">
                {
                  response.response.response.lighthouseResult?.configSettings
                    .emulatedFormFactor
                }
              </h4>
              <span className="text-color">HD+ (1600 x 900)</span>
            </div>
            <div className="box1">
              <h4 className="text-center text-color">Browser</h4>
              <h4 className="lightblack">Chrome 121</h4>
            </div>
            <div className="box1">
              <h4 className="text-center text-color">Connection</h4>
              <h4 className="lightblack">Native speed</h4>
            </div>
          </div>
        </div>
        <hr className="mb-0 mt-5 green" style={{ height: "5px" }} />
        
      
      </div>
    </>
  );
}
