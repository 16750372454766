import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import img from "../../Images/Group.png";
import img1 from "../../Images/Group 42.png";
import Chart from "react-apexcharts";

export default function Waterfall(data) {
  const [activeButton, setActiveButton] = useState(null);

  const getButtonClass = (buttonName) => {
    return `${activeButton === buttonName ? " active" : ""}`;
  };
  const diagnostics = ["All", "HTML", "CSS", "JS", "FONT", "OTHER"];
  const dataArray =
    data.response?.lighthouseResult?.audits["network-requests"]?.details?.items;
  const [openIndex, setOpenIndex] = useState(null);
  const [filteredData, setFilteredData] = useState(dataArray);
  const handleRowClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const mimeTypeMap = {
    All: ["All"],
    HTML: ["text/html"],
    CSS: ["text/css"],
    JS: [
      "application/javascript",
      "application/x-javascript",
      "text/javascript",
    ],
    // Split the string by commas to create an array of MIME types for "FONT"
    FONT: ["application/x-font-woff", "application/x-font-ttf"],
    OTHER: ["other"], // Adjust this based on how 'other' MIME types are identified
  };
  const columns = {
    label1: "URL",
    label2: "Status",
    label3: "Domain",
    label4: "Size",
    label5: "Waterfall Chart (Timeline)",
    label6: "Duration",
    label7: "Priority",
  };
  const totalRequests = dataArray?.length;
  const totalResourceSize = dataArray?.reduce(
    (total, data) => total + parseInt(data.resourceSize, 10),
    0
  );
  const filterData = (filterKey) => {
    if (filterKey === "All") {
      setFilteredData(dataArray);
    } else {
      // Retrieve the array of MIME types for the selected filterKey
      const mimeTypes = mimeTypeMap[filterKey];
      const filtered = dataArray?.filter((data) => {
        // Adjust based on your data structure; check if any MIME type matches
        // For 'OTHER', you'll need specific logic based on your data and criteria
        if (filterKey === "OTHER") {
          // Implement logic to determine if data.mimeType matches 'other' criteria
          // This is placeholder logic
          return !Object.values(mimeTypeMap).flat().includes(data.mimeType);
        } else {
          return mimeTypes.some((mimeType) => data.mimeType.includes(mimeType));
        }
      });
      setFilteredData(filtered);
    }
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    // Map your button names to the mimeType they represent
    // You might need to adjust this mapping based on your actual mimeType values

    filterData(buttonName);
  };
  function getPriorityColor(priority) {
    switch (priority.toLowerCase()) {
      case "high":
        return "#FC619B";
      case "medium":
        return "#F6AB34";
      case "low":
        return "#8CC63E";
      default:
        return "#E2252B";
    }
  }

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const showTooltip = () => {
    setTooltipVisible(true);
  };

  const hideTooltip = () => {
    setTooltipVisible(false);
  };
  const navigate = useNavigate();

  const handleLinkClick = (url) => {
    navigate(url);
  };

  return (
    <>
      <hr className="mb-0 my-5 green" style={{ height: "5px" }} />
      <div className="container col-md-12 Structure Summary2 d-flex align-items-center flex-wrap my-5">
        <div className="container p-0 col-md-5 d-flex align-items-center">
          <h4 className="m-0">
            Waterfall
            <span className="tooltip-container-2">
              <i
                className="fa-solid fa-circle-question green mx-2 cursor-pointer"
                style={{ cursor: "pointer" }}
              ></i>
              <span className="tooltip-text-2">
                A waterfall chart displays sequential values as bars that
                cascade up or down, representing positive and negative changes.
                It provides a visual breakdown of incremental contributions to a
                total, commonly used in financial analysis and project
                management.
              </span>
            </span>
          </h4>
        </div>
        <div
          className="container p-0 col-md-7 d-flex justify-content-end flex-wrap py-4 tab"
          style={{ gap: "12px" }}
        >
          {diagnostics.map((diagnostic, index) => (
            <button
              key={index}
              className={getButtonClass(diagnostic)}
              onClick={() => handleButtonClick(diagnostic)}
            >
              {diagnostic}
            </button>
          ))}
        </div>
      </div>
      <div className="container Waterfall overflow-hidden">
        <div
          className="container p-0 WaterfallTitle"
          style={{
            borderLeft: "2px solid #EFEFEF",
            borderRight: "2px solid #EFEFEF",
          }}
        >
          <div className="tableHeading col-md-12">
            <div className="row m-0" style={{ flexWrap: "nowrap" }}>
              <div
                className="border-bottom-2 py-3 col-md-3 urlHeading col-sm-3 p-0 col-8 ps-4"
                style={{ whiteSpace: "nowrap" }}
              >
                <p className="m-0">{columns.label1}</p>
              </div>
              <div className="border-bottom-2 py-3 col-md-1 col-sm-1 p-0 col-3">
                <p className="m-0">{columns.label2}</p>
              </div>
              <div className="border-bottom-2 py-3 col-md-2 col-sm-2 p-0 col-6">
                <p className="m-0">{columns.label3}</p>
              </div>
              <div className="border-bottom-2 py-3 col-md-1 col-sm-1 p-0 col-4">
                <p className="m-0">{columns.label4}</p>
              </div>
              <div className="border-bottom-2 py-3 col-md-1 col-sm-1 p-0 col-4">
                <p className="m-0">{columns.label7}</p>
              </div>
              <div className="border-bottom-2 py-3 col-md-3 three col-sm-3 p-0 col-8">
                <p className="m-0">{columns.label5}</p>
              </div>
              <div className="border-bottom-2 py-3 col-md-1 col-sm-1 p-0 col-3">
                <p className="m-0">{columns.label6}</p>
              </div>
            </div>
          </div>
          {filteredData &&
            filteredData.map((data, index) => (
              <div
                className="container p-0 tableData"
                data-type={data.mimeType}
              >
                <div>
                  <div
                    key={index}
                    className={`container table mb-0 border-bottom-2 p-0 align-items-center col-md-12 d-flex py-1`}
                    onClick={() => handleRowClick(index)}
                  >
                    <div
                      className="Waterfall urlHeading col-md-3  col-sm-3 col-8 m-0 urlPath 

                    "
                    >
                      <p className="url">
                        <span
                          className="origin-url py-0 ps-4 tooltip-container"
                          onMouseEnter={() => setTimeout(showTooltip, 500)}
                          onMouseLeave={hideTooltip}
                        >
                          {/* {data.mimeType} */}
                          {new URL(data.url).pathname}
                          {tooltipVisible && (
                            <span className="tooltip" style={{ zIndex: "20" }}>
                              {data.url}
                            </span>
                          )}
                        </span>
                      </p>
                    </div>
                    <div
                      className="col-md-1 col-sm-1 col-3 status hide tooltip-container-1"
                      onMouseEnter={() => setTimeout(showTooltip, 1000)}
                      onMouseLeave={hideTooltip}
                    >
                      <button className="m-0">
                        {data.statusCode}
                        {tooltipVisible && (
                          <span className="tooltip-1">
                            Status: {data.statusCode}
                          </span>
                        )}
                      </button>
                    </div>
                    <div
                      className="col-md-2 col-sm-2 col-6 hide tooltip-container-1"
                      onMouseEnter={() => setTimeout(showTooltip, 1000)}
                      onMouseLeave={hideTooltip}
                    >
                      <p className="m-0">
                        {data.entity}
                        {tooltipVisible && (
                          <span className="tooltip-1">
                            Domain: {data.entity}
                          </span>
                        )}
                      </p>
                    </div>
                    <div
                      className="col-md-1 col-sm-1 col-4 tooltip-container-1"
                      onMouseEnter={() => setTimeout(showTooltip, 500)}
                      onMouseLeave={hideTooltip}
                    >
                      <p className="m-0">
                        {data.resourceSize > 1024
                          ? `${(data.resourceSize / 1024).toFixed(2)} KB`
                          : `${data.resourceSize} Bytes`}
                        {tooltipVisible && (
                          <span className="tooltip-1">
                            Size:{" "}
                            {data.resourceSize > 1024
                              ? `${(data.resourceSize / 1024).toFixed(
                                  2
                                )} KB (${data.resourceSize.toLocaleString()} Bytes)`
                              : `${data.resourceSize.toLocaleString()} Bytes`}
                          </span>
                        )}
                      </p>
                    </div>
                    <div
                      className="col-md-1 col-sm-1 col-4 tooltip-container-1"
                      onMouseEnter={() => setTimeout(showTooltip, 1000)}
                      onMouseLeave={hideTooltip}
                    >
                      <p
                        className="m-0"
                        style={{ color: getPriorityColor(data.priority) }}
                      >
                        {data.priority}
                        {tooltipVisible && (
                          <span className="tooltip-1">
                            Priority: {data.priority}
                          </span>
                        )}
                      </p>
                    </div>

                    <div className="col-md-3 three col-sm-3 col-8 hide">
                      {/* {data.rendererStartTime}
                      {data.networkEndTime} */}
                    </div>
                    <div className="col-md-1 col-sm-1 hide col-3 d-flex justify-content-start">
                      <p className="m-0">
                        {data.networkEndTime
                          ? parseFloat(data.networkEndTime / 1000).toFixed(2)
                          : "0.00"}{" "}
                        Sec
                      </p>
                    </div>
                  </div>
                  <div className="waterfall-dropdown container px-4 m-0">
                    {openIndex === index && (
                      <>
                        <div className="container p-0 my-3">
                          <button className="m-0">Header</button>
                        </div>
                        <div className="container dropdown-box p-0">
                          <div className="container dropdown-content my-3 px-4">
                            <span>
                              <a
                                href={data.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {data.url}
                              </a>
                            </span>

                            <p className="mt-2 borderBottom">
                              <b>Response Version</b>
                            </p>
                            <div
                              className="d-flex borderBottom"
                              style={{ gap: "180px" }}
                            >
                              <p className="mt-5 mb-0">
                                <b>Response Headers</b>
                              </p>
                              <span>HTTP/2</span>
                            </div>
                            <div className="overflow-hidden">
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>date</b>
                                </div>
                                <div className="col-md-7">
                                  {/* {console.log(data)} */}
                                  Sat, 10 Feb 2024 06:33:56 GMT
                                </div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>content-security-policy</b>
                                </div>
                                <div className="col-md-7">
                                  script-src 'self' https://*.speedvitals.com
                                  cdn.jsdelivr.net
                                  https://browser.sentry-cdn.com https://js
                                  .sentry-cdn.com https://*.clarity.ms
                                  https://c.bing.com *.facebook.com
                                  *.facebook.net *.google-analytics .com
                                  *.chatra.io *.googletagmanager.com
                                  *.googleadservices.com *.googlesyndication.com
                                  https://challenges .cloudflare.com
                                  https://js.stripe.com *.ads-twitter.com
                                  *.doubleclick.net *.google.com 'unsafe-inline'
                                  'unsafe-eval';connect-src 'self'
                                  https://*.speedvitals.com *.sentry.io
                                  *.google-analytics.com https
                                  ://www.facebook.com
                                  https://analytics.google.com
                                  *.googletagmanager.com *.googleadservices.com
                                  *.googlesyndication .com *.doubleclick.net
                                  https://*.clarity.ms https://c.bing.com
                                  *.google-analytics.com https://api.stripe .com
                                  https://cdn.speedvitals.com;frame-src 'self'
                                  https://*.speedvitals.com *.facebook.com
                                  *.facebook .net https://accounts.google.com
                                  https://challenges.cloudflare.com
                                  https://*.doubleclick.net https:/
                                  /js.stripe.com https://hooks.stripe.com
                                  https://chat.chatra.io/;img-src 'self' https:
                                  data: blob: *.google-analytics .com
                                  *.doubleclick.net *.google.com
                                  *.googletagmanager.com
                                  https://*.stripe.com;worker-src
                                  blob:;default-src 'self' https://*.clarity.ms
                                  https://c.bing.com unsafe-inline
                                  https://cdn.speedvitals.com;base-uri 'self'
                                  ;block-all-mixed-content;font-src 'self'
                                  https: data:;frame-ancestors 'self'
                                  https://stripe.com/ https ://*.stripe.com
                                  https://*.speedvitals.com;object-src
                                  'none';style-src 'self' https: 'unsafe-inline'
                                </div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>x-content-type-options</b>
                                </div>
                                <div className="col-md-7">nosniff</div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>strict-transport-security</b>
                                </div>
                                <div className="col-md-7">
                                  max-age=31536000; includeSubDomains; preload
                                </div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>via</b>
                                </div>
                                <div className="col-md-7">
                                  1.1 varnish (Varnish/6.2)
                                </div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>x-permitted-cross-domain-policies</b>
                                </div>
                                <div className="col-md-7">none</div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>cf-cache-status</b>
                                </div>
                                <div className="col-md-7">HIT</div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>age</b>
                                </div>
                                <div className="col-md-7">697664</div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>x-dns-prefetch-control</b>
                                </div>
                                <div className="col-md-7">off</div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>content-encoding</b>
                                </div>
                                <div className="col-md-7">br</div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>x-xss-protection</b>
                                </div>
                                <div className="col-md-7">0</div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>referrer-policy</b>
                                </div>
                                <div className="col-md-7">
                                  strict-origin-when-cross-origin
                                </div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>last-modified</b>
                                </div>
                                <div className="col-md-7">
                                  Sun, 20 Feb 2022 13:01:53 GMT
                                </div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>server</b>
                                </div>
                                <div className="col-md-7">cloudflare</div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>etag</b>
                                </div>
                                <div className="col-md-7">
                                  W/"5c9f-17f17396a99"
                                </div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>x-download-options</b>
                                </div>
                                <div className="col-md-7">noopen</div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>expect-ct</b>
                                </div>
                                <div className="col-md-7">max-age=0</div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>x-frame-options</b>
                                </div>
                                <div className="col-md-7">SAMEORIGIN</div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>content-type</b>
                                </div>
                                <div className="col-md-7">
                                  text/css; charset=UTF-8
                                </div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>vary</b>
                                </div>
                                <div className="col-md-7">Accept-Encoding</div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>report-to</b>
                                </div>
                                <div className="col-md-7">
                                  "endpoints : url :
                                  https:\/\/a.nel.cloudflare.com\/report\/v3?s=mEaXdnH6IlRz9xcmv6osr8p6ev6te6b13RLdaa6OAerx15gvytY%2FYDeDvt54K0SKPFkW7xEs8lVflv5bvjvIBSMQblBNZy5Sph%2B6%2Fh01w%2B8AtPPoM8RymkHVK%2B5IMMSbMOskGw8kmVeOexYo%2FW8%3D,
                                  group : cf-nel , max_age :604800"
                                </div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>x-varnish</b>
                                </div>
                                <div className="col-md-7">15287644</div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>cache-control</b>
                                </div>
                                <div className="col-md-7">
                                  public, max-age=3600
                                </div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>cf-ray</b>
                                </div>
                                <div className="col-md-7">
                                  853252cec88e0627-IAD
                                </div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3">
                                  <b>Request Version</b>
                                </div>
                                <div className="col-md-7"></div>
                              </div>
                              <div
                                className="d-flex borderBottom1"
                                style={{ gap: "180px" }}
                              >
                                <p className="mt-5 mb-0">
                                  <b>Response Headers</b>
                                </p>
                                <span>HTTP/2</span>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>sec-ch-ua</b>
                                </div>
                                <div className="col-md-7">
                                  "Chromium";v="120", "Google Chrome";v="120",
                                  "Lighthouse";v="11.4.0"
                                </div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>Referer</b>
                                </div>
                                <div className="col-md-7">
                                  https://speedvitals.com/
                                </div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>sec-ch-ua-mobile</b>
                                </div>
                                <div className="col-md-7">?0</div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>User-Agent</b>
                                </div>
                                <div className="col-md-7">
                                  Mozilla/5.0 (Windows NT 10.0; Win64; x64)
                                  AppleWebKit/537.36 (KHTML, like Gecko)
                                  Chrome/120.0.6099.199 Safari/537.36
                                </div>
                              </div>
                              <div className="col-md-12 d-flex">
                                <div className="col-md-3 d-flex justify-content-end pe-3">
                                  <b>sec-ch-ua-platform</b>
                                </div>
                                <div className="col-md-7">"macOS"</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          <div
            className="container col-md-12 tableBottom heading border-bottom-2 p-0 col-md-12 d-flex py-3"
            style={{ whiteSpace: "nowrap" }}
          >
            <div className="col-md-6 col-sm-12 col-12 ps-4">
              <p className="m-0">Total Requests : {totalRequests}</p>
            </div>
            <div className="col-md-3 col-sm-3 col-12">
              <p className="m-0">
                {totalResourceSize
                  ? `${(totalResourceSize / 1024 / 1024).toFixed(2)} MB`
                  : "0.00 MB"}
              </p>
            </div>
            <div className="col-md-3 col-sm-3 col-12 d-flex justify-content-end pe-5">
              <p className="m-0">Fully Loaded : 4.8s (onLoad: 4.75s)</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// {
/* <Chart
type="bar"
width={1349}
height={560}
series={[
{
  name: "Hydro-Electronic",
  data: [45, 78, 39, 50, 100],
  color: "#E45858",
},
{
  name: "Oil",
  data: [145, 178, 79, 120, 100],
  color: "#C488C6",
},
{
  name: "Oil",
  data: [55, 478, 279, 820, 100],
  color: "#8CC63E",
},
{
  name: "Coal",
  data: [190, 321, 121, 537, 100],
  color: "#FFAB00",
},
]}
options={{
title: {
  text: "Energy Consumption in Year",
},
chart: {
  stacked: true,
},
plotOptions: {
  bar: {
    horizontal: true,
    columnWidth: "20%",
  },
},
xaxis: {
  title: {
    text: "Energy Consumption in Year",
  },
  tooltip: {
    enabled: true, // Enable tooltips on hover
  },
  categories: ["2019", "2020", "2021", "2022", "2023"],
},
}}
/> */
// }
