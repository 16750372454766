import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Axios from "axios";
import Summary1 from "./Summary/Summary1";
import Timeline from "./Summary/Timeline";
import Performance from "./Summary/Performance";
// import Sturcture from "./Summary/Structure";
import Waterfall from "./Summary/Waterfall";
import Video from "./Summary/Video";
import Structure from "./Summary/Structure";
export default function Summary(response, reportId, generate, responsedata) {
  const getHash = () => {
    return window.location.hash.slice(1);
  };
  const hash = getHash();
  const [url, setUrl] = useState("");
  const [responseData, setResponseData] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const currentSection = new URLSearchParams(location.search).get("section");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlParam = params.get("url");
    setUrl(urlParam || "");
  }, [location.search]);

  if(response.response.report_id !== undefined ){
    const newUrl = new URL(window.location.href);
    const reportId = response.response.report_id;

  newUrl.searchParams.set("report_id", reportId);
  newUrl.searchParams.set("generate", false);
  window.history.replaceState(null, null, newUrl.toString());
  }
  return (
    <div>
      <Summary1 data={response} />
      {response.response.report_id !== undefined && (
        <>
          <Timeline response={response} />
          <Performance response={response} />
          <Structure response={response} />
          <Waterfall response={response.response} />
          <Video response={response} />
        </>
      )}
    </div>
  );
  
}
