import React from 'react'
import BlogSection1 from './Blog/BlogSection1'
import BlogSection2 from './Blog/BlogSection2'
import BlogSection3 from './Blog/BlogSection3'
import BlogSection4 from './Blog/BlogSection4'

export default function Blog() {
  return (
    <div>
      <BlogSection1 />
      <BlogSection2 />
      <BlogSection3 />
      <BlogSection4 />
    </div>
  )
}
