import React from "react";
import img from "../../Images/Group 32.png";

export default function HeroSection2() {
  return (
    <div className="HeroSection2 container-fluid d-flex py-5">
      <div className="container paddingSpace1 d-flex col-md-12 first-child flex-wrap">
        <div className="img-div d-flex justify-content-center align-items-center col-md-6 col-12">
          <img className="w-75" src={img} alt="Image" />
        </div>
        <div className="content-div d-flex flex-column justify-content-center col-md-6 col-12 ps-5 h-auto">
          <h2 className="text-start font mb-4">Test And Optimize Page Speed</h2>
          <div className="d-flex align-items-center mb-2">
            <span className="d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-angle-right green pe-2"></i>
            </span>
            <h4 className="text-start green m-0">How fast is your website?</h4>
          </div>
          <p className="text-start ps-3">
            A slow website will reduce your organic SEO traffic
            <br /> from Google and lower conversion rates for people
            <br /> who find your site.
          </p>
          <div className="d-flex align-items-center mb-2">
            <span className="d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-angle-right green pe-2"></i>
            </span>
            <h4 className="text-start green m-0">Free website analysis</h4>
          </div>
          <p className="text-start ps-3">
            Analyze page speed to see what resources your
            <br /> website loads, watch how it loads step by step, and
            <br /> check if third-party code is slowing you down..
          </p>
          <div className="d-flex align-items-center mb-2">
            <span className="d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-angle-right green pe-2"></i>
            </span>
            <h4 className="text-start green m-0">
              Recommendations to speed up your website
            </h4>
          </div>
          <p className="text-start ps-3">
            Our page speed checker shows you opportunities to
            improve your page load time.
          </p>
        </div>
      </div>
    </div>
  );
}
