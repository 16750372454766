import React from "react";
import img1 from "../../Images/shadow.png";

export default function BlogSection1() {
  return (
    <div className="HeroSection1 BlogSection1 PricingSection1 container-fluid h-auto p-0 d-flex flex-column justify-content-between">
      <div className="main container d-flex justify-content-evenly col-md-12 col-sm-12 col-12 d-flex flex-wrap">
        <div className="d-flex justify-content-start h-auto col-md-3">
          <img className="img" src={img1} alt="Image" />
        </div>
        <div className="heading paddingSpace d-flex flex-column justify-content-end px-2 col-md-6">
          <h2 className="text-center">Blog</h2>
          <p className="pt-2 mb-5 text-center">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem
          </p>
        </div>
        <div className="d-flex justify-content-end col-md-3">
          <img className="img1" src={img1} alt="Image" />
        </div>
      </div>
      {/* <div className="input-div container d-flex col-md-12 col-sm-12 col-12 d-flex flex-wrap justify-content-evenly align-items-end"></div> */}
      <div className="position-relative container text-center">
        <i
          for="IconTag"
          className="fa-solid fa-magnifying-glass position-absolute left-0 top-50 transform-translate-n50 start-2 translate-middle-y ps-3 fs-4"
        ></i>
        <input
          type="text"
          id="IconTag"
          className="py-2 ps-5 col-md-8 col-sm-10 col-12"
          placeholder="Search"
        />
      </div>
    </div>
  );
}
