import React from 'react'
import HeroSection1 from './Home/HeroSection1'
import HeroSection2 from './Home/HeroSection2'
import HeroSection3 from './Home/HeroSection3'
import HeroSection4 from './Home/HeroSection4'

export default function Home() {
  return (
    <div>
      <HeroSection1 />
      <HeroSection2 />
      <HeroSection3 />
      <HeroSection4 />
    </div>
  )
}
