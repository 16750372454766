import React, { useState } from "react";

export default function PricingSection3() {
  const [openIndex, setOpenIndex] = useState(-1);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  return (
    <div className="PricingSection3 container-fluid">
      <h1 className="text-center mb-5">Frequently Asked Questions</h1>
      <div className="container paddingSpace1">
        <div className="about-list container">
          {[0, 1, 2, 3, 4, 5].map((index) => (
            <div key={index}>
              <div
                className="d-flex justify-content-between align-items-center"
                onClick={() => toggleAccordion(index)}
                style={{ cursor: "pointer" }}
              >
                <h4 className="ms-3">
                  {
                    [
                      "What is a Monitoring Slot?",
                      "How many Monitored Slots do I need?",
                      "What is an On-Demand Test?",
                      "Wow - The Basic/Free GTmetrix plan has a lot of features! Why?",
                      "Will I be charged every month/year if I subscribe to GTmetrix PRO?",
                      "What payment options does GTmetrix accept?",
                    ][index]
                  }
                </h4>
                <i className={`fa-solid fa-chevron-${openIndex === index ? "up" : "down"} pe-3`}></i>
              </div>
              <hr style={{ height: "2px", color: "#5DA844",margin:"10px 0px" }} />
              <div
                className={`drop-down ${openIndex === index ? "open" : "closed"} ps-3`}
                style={{
                  maxHeight: openIndex === index ? "320px" : "0",
                  opacity: openIndex === index ? "1" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.3s ease, opacity 0.3s ease",
                }}
              >
                <p>
                  Analyze page speed to see what resources your
                  <br /> website loads, watch how it loads step by step, and
                  <br /> check if third-party code is slowing you down.
                </p>
                <p>
                  Analyze page speed to see what resources your
                  <br /> website loads, watch how it loads step by step, and
                  <br /> check if third-party code is slowing you down.
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
